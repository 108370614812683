import * as React from 'react'
import { Helmet } from 'react-helmet'
import Bounce from 'react-reveal/Bounce'
import Footer from '../../../components/Footer/Footer'
import Gallery, { useGallery } from '../../../components/Gallery/Gallery'
import images from '../../../components/Gallery/ImageLists/tusk-2018/tusk-2018-images'

import './tusk-2018.scss'

const Tusk2018 = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <>
      <div id="tusk-2018">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <title>Emso</title>
          <meta name="description" content="Emso thank you page for Tusk 2018 Safaricom Marathon" />
        </Helmet>

        <section id="hero" className="hero fullscreen wrap">
          <nav className="wrap nav">
            <div className="container cf">
              <a href="https://www.emso.com/" title="Back to Emso" id="logo">
                <img src="/images/tusk-2018/images/emso-logo.svg" alt="Emso logo" width="90" height="62" />
              </a>
            </div>
          </nav>
          <div className="statement">
            <div className="container cf">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <h1>Some of Africa's most magnificent creatures are now just a little safer, thanks to you</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="video-link-container">
            <div className="container cf">
              <a href="#safaricom-video" className="go-to-video" title="Go to video">
                <span className="video-link">
                  <h6 className="yellow">Watch the video of our Trip to Kenya</h6>
                </span>
                <img
                  alt="arrow button down"
                  className="arrow-btn-down"
                  src="/images/tusk-2018/images/down-arrow-yellow.svg"
                  width="32"
                  height="32"
                />
              </a>
            </div>
          </div>
          <div className="img-container-cover">
            <img src="/images/tusk-2018/images/Emso-tusk-hero.jpg" alt="Emso Tusk thank you page HERO" />
          </div>
        </section>

        <main id="content" className="wrap" role="main">
          <section id="tusk-box" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <div className="content">
                    <img src="/images/tusk-2018/images/tusk-logo.svg" alt="Tusk logo" className="tusk-logo" width="219" height="96" />
                    <p>
                      As you are probably aware, Emso is a strong supporter of Tusk, an organization that is advancing conservation across
                      Africa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="number-box" className="section-padding">
            <div className="right-column">
              <div className="title h1 yellow">
                <span>£</span>100,000
              </div>
              <img src="/images/tusk-2018/images/elephant.svg" className="elephant-icon" alt="Elephant icon" width="65" height="65" />
              <p>
                This year, thanks to your help and support, we've made our <strong>largest contribution to date</strong>.
              </p>
            </div>
          </section>

          <section id="text-and-quiz" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <p>
                    The <strong>£100,000 raised from our Quiz Night</strong> at the Royal Horseguards Hotel and through the sterling efforts
                    of our marathon runners in Kenya will be used by Tusk and its partners in a wide range of projects benefiting
                    sustainable development, education, healthcare, and wildlife conservation.{' '}
                  </p>
                  <p>
                    We are pleased to announce that Emso received special recognition for its fundraising this year, and following the
                    Safaricom Marathon, we were presented with the <strong>Corporate Fundraising Award by the First Lady of Kenya</strong>,
                    Margaret Gakuo Kenyatta, herself!
                  </p>
                  <p>
                    Our award-winning efforts in 2018 to help some of Africa's most magnificent animals and the environment they inhabit
                    represents a real milestone in our work with Tusk.{' '}
                  </p>
                  <h3>Thank you!</h3>
                  <p>
                    We cannot thank you enough for attending our Quiz Night, for donating to our personal fundraisers, and finally, for
                    wishing us luck as we tackled the tough Safaricom marathon course. We hope the video below gives you a glimpse into our
                    visit to Lewa, and most importantly, to the children and animals that are directly benefitting from your support.
                  </p>
                  <p>You can see photos from our excellent and very well-attended Quiz Night on our dedicated page.</p>
                </div>
              </div>
            </div>
            <Bounce bottom>
              <div className="quiz-box right-column">
                <div className="row">
                  <div className="cols col-base-12 col-sm-6">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/01.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/02.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/03.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6 no-mobile">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/04.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6 no-mobile">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/05.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6 no-mobile">
                    <div className="img-container">
                      <img src="/images/tusk-2018/images/quiznight/promo/06.jpg" />
                    </div>
                  </div>
                </div>
                <span className="cta">
                  <a href="/tusk/2018/quiz-night" title="See all quiz photos" target="_blank" className="btn">
                    Quiz Night photos
                  </a>
                </span>
              </div>
            </Bounce>
          </section>

          <section id="safaricom-video" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <h1>Our 2018 Safaricom Marathon</h1>
                  <div className="video">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/_JRTgT-ZoeQ?rel=0&amp;showinfo=0"
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <blockquote>
                    One of the top ten races to run in your life.<span className="author">— Runner’s World</span>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>

          <Gallery images={images} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />
          <Bounce bottom>
            <section id="safaricom-galery">
              <div className="container">
                <div className="row">
                  <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                    <h6>Photo gallery</h6>
                  </div>
                </div>
                <div className="row photos">
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0081.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0081.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0135.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0135.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0216.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0216.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0375.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0375.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0464.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0464.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_5473.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_5473.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0577.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0577.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/DSC_0602.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/DSC_0602.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_0703.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_0703.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_4469.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_4469.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_4581.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_4581.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_4610.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_4610.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_4669.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_4669.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/large/IMG_4673.JPG')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/small/IMG_4673.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Bounce>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default Tusk2018;
