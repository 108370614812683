
const tusk2018Images = [
    '/images/tusk-2018/images/safari/large/DSC_0081.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0135.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0216.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0375.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0464.JPG',
    '/images/tusk-2018/images/safari/large/IMG_5473.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0577.JPG',
    '/images/tusk-2018/images/safari/large/DSC_0602.JPG',
    '/images/tusk-2018/images/safari/large/IMG_0703.JPG',
    '/images/tusk-2018/images/safari/large/IMG_4469.JPG',
    '/images/tusk-2018/images/safari/large/IMG_4581.JPG',
    '/images/tusk-2018/images/safari/large/IMG_4610.JPG',
    '/images/tusk-2018/images/safari/large/IMG_4669.JPG',
    '/images/tusk-2018/images/safari/large/IMG_4673.JPG'
];

export default tusk2018Images;